define("plutof/components/search/filter-panels/annotation/record-properties", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "annotation.routes.search.recordProperties"}}
      @collapse={{true}}
  >
      <Layout::RegularGrid @columns={{4}}>
          <div>
              {{common/local-label "annotation.routes.search.contentType"}}
  
              <MultipleSelection
                  @content={{@filterInputs.content_type.choices}}
                  @selections={{@filterInputs.content_type.value}}
                  @prompt="---"
                  @optionValuePath="value"
                  @optionLabelPath="label" />
          </div>
  
          <Search::FilterInput @filter={{@filterInputs.sequence_unite_status}} />
      </Layout::RegularGrid>
  
      <Layout::SeparatedRow>
          <Search::FilterInput @filter={{@filterInputs.sequence_insd}} />
      </Layout::SeparatedRow>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "XEhjk5JS",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@collapse\"],[[28,[37,1],[\"annotation.routes.search.recordProperties\"],null],true]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@columns\"],[4]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[12],[1,\"\\n            \"],[1,[28,[35,3],[\"annotation.routes.search.contentType\"],null]],[1,\"\\n\\n            \"],[8,[39,4],null,[[\"@content\",\"@selections\",\"@prompt\",\"@optionValuePath\",\"@optionLabelPath\"],[[30,1,[\"content_type\",\"choices\"]],[30,1,[\"content_type\",\"value\"]],\"---\",\"value\",\"label\"]],null],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[8,[39,5],null,[[\"@filter\"],[[30,1,[\"sequence_unite_status\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[39,6],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,5],null,[[\"@filter\"],[[30,1,[\"sequence_insd\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@filterInputs\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"layout/regular-grid\",\"common/local-label\",\"multiple-selection\",\"search/filter-input\",\"layout/separated-row\"]]",
    "moduleName": "plutof/components/search/filter-panels/annotation/record-properties.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});