define("plutof/components/search/filter-panels/unite-sh-properties", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "plutof/models/globalkey/threshold"], function (_exports, _component, _templateFactory, _component2, _object, _service, _threshold) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "Search.panelProperties"}}
      @collapse={{true}}
  >
      <Layout::RegularGrid @columns={{6}}>
          <Search::FilterInput @filter={{@filterInputs.designators}} />
          <Search::FilterInput @filter={{@filterInputs.strain_idprim}} />
          <Search::FilterInput @filter={{@filterInputs.specimen_idprim}} />
          <Search::FilterInput @filter={{@filterInputs.country}} />
      </Layout::RegularGrid>
  
      <Layout::RegularGrid @columns={{6}}>
          <Search::FilterInput @filter={{@filterInputs.sequence_accession_nr}} />
  
          <div>
              {{plutof-labelc "globalkey.threshold.threshold"}}
  
              {{#resolve-promise this.thresholds as |thresholds|}}
                  <MultipleSelection
                      @content={{thresholds}}
                      @selections={{@filterInputs.threshold.value}}
                      @optionLabelPath="representation"
                      @prompt="---" />
              {{/resolve-promise}}
          </div>
  
          <Search::FilterInput @filter={{@filterInputs.version}} />
          <Search::FilterInput @filter={{@filterInputs.form_status}} />
          <Search::FilterInput @filter={{@filterInputs.compound_cluster}} />
      </Layout::RegularGrid>
  
      <Layout::RegularGrid @columns={{6}}>
          <Search::FilterInput @filter={{@filterInputs.is_singleton}} />
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "pkCQ56V2",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@collapse\"],[[28,[37,1],[\"Search.panelProperties\"],null],true]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@columns\"],[6]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@filter\"],[[30,1,[\"designators\"]]]],null],[1,\"\\n        \"],[8,[39,3],null,[[\"@filter\"],[[30,1,[\"strain_idprim\"]]]],null],[1,\"\\n        \"],[8,[39,3],null,[[\"@filter\"],[[30,1,[\"specimen_idprim\"]]]],null],[1,\"\\n        \"],[8,[39,3],null,[[\"@filter\"],[[30,1,[\"country\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[39,2],null,[[\"@columns\"],[6]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@filter\"],[[30,1,[\"sequence_accession_nr\"]]]],null],[1,\"\\n\\n        \"],[10,0],[12],[1,\"\\n            \"],[1,[28,[35,4],[\"globalkey.threshold.threshold\"],null]],[1,\"\\n\\n\"],[6,[39,5],[[30,0,[\"thresholds\"]]],null,[[\"default\"],[[[[1,\"                \"],[8,[39,6],null,[[\"@content\",\"@selections\",\"@optionLabelPath\",\"@prompt\"],[[30,2],[30,1,[\"threshold\",\"value\"]],\"representation\",\"---\"]],null],[1,\"\\n\"]],[2]]]]],[1,\"        \"],[13],[1,\"\\n\\n        \"],[8,[39,3],null,[[\"@filter\"],[[30,1,[\"version\"]]]],null],[1,\"\\n        \"],[8,[39,3],null,[[\"@filter\"],[[30,1,[\"form_status\"]]]],null],[1,\"\\n        \"],[8,[39,3],null,[[\"@filter\"],[[30,1,[\"compound_cluster\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[39,2],null,[[\"@columns\"],[6]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@filter\"],[[30,1,[\"is_singleton\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@filterInputs\",\"thresholds\"],false,[\"plutof-panel/simple\",\"i18n-t\",\"layout/regular-grid\",\"search/filter-input\",\"plutof-labelc\",\"resolve-promise\",\"multiple-selection\"]]",
    "moduleName": "plutof/components/search/filter-panels/unite-sh-properties.hbs",
    "isStrictMode": false
  });
  let SearchUniteSHPropertyFilters = (_class = class SearchUniteSHPropertyFilters extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
    }
    get thresholds() {
      return (0, _threshold.loadThresholds)(this.store, {
        descending: true
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "thresholds", [_object.computed], Object.getOwnPropertyDescriptor(_class.prototype, "thresholds"), _class.prototype), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SearchUniteSHPropertyFilters);
});