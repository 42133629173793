define("plutof/components/plutof-select/object", ["exports", "@ember/component", "@ember/object", "@ember/utils"], function (_exports, _component, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let ObjectSelect = (_class = class ObjectSelect extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "optionValuePath", 'id');
      _defineProperty(this, "optionLabelPath", 'representation');
      _defineProperty(this, "prompt", '---');
      _defineProperty(this, "selectClass", 'form-control');
      _defineProperty(this, "disabled", false);
      _defineProperty(this, "oneWay", false);
      _defineProperty(this, "content", []);
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      let value = null;
      const valuePath = this.optionValuePath;
      const selection = this.selection;
      if (!(0, _utils.isNone)(selection)) {
        value = (0, _object.get)(selection, valuePath);
      }
      this.set('value', value);
    }
    valueChanged(value) {
      if (this.isDestroyed) {
        return;
      }
      const content = this.content;
      if ((0, _utils.isNone)(content)) {
        return;
      }
      const selection = content.findBy(this.optionValuePath, value);
      if (!this.oneWay) {
        this.set('selection', selection);
      }
      const changeAction = this.selectionChanged;
      if (!(0, _utils.isNone)(changeAction)) {
        changeAction(selection, value);
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "valueChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "valueChanged"), _class.prototype), _class);
  var _default = _exports.default = ObjectSelect;
});