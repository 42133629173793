define("plutof/templates/components/clipboard/darwin-core-export-persons", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "M+8qNZQ7",
    "block": "[[[10,0],[14,0,\"add-component\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"component-part\"],[12],[1,\"\\n        \"],[8,[39,0],null,[[\"@path\",\"@source\"],[[30,1],\"options\"]],null],[1,\"\\n\\n        \"],[10,0],[15,0,[29,[\"control-group \",[52,[30,0,[\"personValidity\"]],\"\",\"has-error\"]]]],[12],[1,\"\\n\"],[6,[39,2],[[30,0,[\"newPerson\"]]],null,[[\"default\"],[[[[1,\"                \"],[8,[39,3],null,[[\"@value\",\"@update\",\"@params\"],[[30,2],[30,3],[28,[37,4],null,[[\"inputClasses\",\"sideActionClasses\"],[\"form-control control\",\"btn btn-default no-error-addon-button input-group-in-add-component--middle\"]]]]],null],[1,\"\\n\"]],[2,3]]]]],[1,\"        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"component-part\"],[12],[1,\"\\n        \"],[10,0],[15,0,[29,[\"control-group \",[52,[30,0,[\"emailValidity\"]],\"\",\"has-error\"]]]],[12],[1,\"\\n            \"],[8,[39,5],[[24,0,\"form-control control\"]],[[\"@type\",\"@value\",\"@placeholder\"],[\"text\",[30,0,[\"newEmail\"]],[30,1,[\"child\",\"children\",\"email\",\"label\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"component-part component-part--button\"],[12],[1,\"\\n        \"],[11,\"button\"],[16,\"disabled\",[28,[37,6],[[30,0,[\"addButtonValidity\"]]],null]],[24,0,\"btn btn-default control\"],[4,[38,7],[\"click\",[30,0,[\"addDatum\"]]],null],[12],[1,\"\\n            \"],[10,1],[15,0,[28,[37,8],[\"ok\"],null]],[12],[13],[1,\"\\n            \"],[1,[28,[35,9],[\"General.Add\"],null]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,10],null,[[\"@records\",\"@remove\"],[[30,4],[30,0,[\"removeDatum\"]]]],null],[1,\"\\n\"]],[\"@options\",\"value\",\"update\",\"@data\"],false,[\"plutof-labelc\",\"if\",\"async/bind-relation\",\"agent/person/auto-complete\",\"hash\",\"input\",\"not\",\"on\",\"icon\",\"i18n-t\",\"common/record-list\"]]",
    "moduleName": "plutof/templates/components/clipboard/darwin-core-export-persons.hbs",
    "isStrictMode": false
  });
});