define("plutof/templates/components/plutof-select/object", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "MJO4gIRX",
    "block": "[[[6,[39,0],null,[[\"value\",\"one-way\",\"action\",\"class\",\"disabled\"],[[30,0,[\"value\"]],true,[28,[37,1],[[30,0],\"valueChanged\"],null],[30,0,[\"selectClass\"]],[30,0,[\"disabled\"]]]],[[\"default\"],[[[[41,[30,0,[\"prompt\"]],[[[6,[30,1,[\"option\"]],null,[[\"value\"],[null]],[[\"default\"],[[[[1,\"            \"],[1,[30,0,[\"prompt\"]]],[1,\"\\n\"]],[]]]]]],[]],null],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"content\"]]],null]],null],null,[[[6,[30,1,[\"option\"]],null,[[\"value\"],[[28,[37,5],[[30,2],[30,0,[\"optionValuePath\"]]],null]]],[[\"default\"],[[[[1,\"            \"],[1,[28,[35,5],[[30,2],[30,0,[\"optionLabelPath\"]]],null]],[1,\"\\n\"]],[]]]]]],[2]],null]],[1]]]]]],[\"xs\",\"option\"],false,[\"x-select\",\"action\",\"if\",\"each\",\"-track-array\",\"get\"]]",
    "moduleName": "plutof/templates/components/plutof-select/object.hbs",
    "isStrictMode": false
  });
});