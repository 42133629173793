define("plutof/templates/components/multiple-selection", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "tx79CKVt",
    "block": "[[[1,[28,[35,0],null,[[\"content\",\"optionValuePath\",\"optionLabelPath\",\"selection\",\"prompt\",\"selectClass\",\"disabled\"],[[33,1],[28,[37,2],[[30,1],\"id\"],null],[28,[37,2],[[30,2],\"representation\"],null],[33,3],[33,4],[33,5],[30,3]]]]],[1,\"\\n\\n\"],[8,[39,6],null,[[\"@records\",\"@remove\"],[[30,0,[\"selections\"]],[30,0,[\"removeRecord\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,7],[[30,4],[30,2]],null]],[1,\"\\n\"]],[4]]]]],[1,\"\\n\"]],[\"@optionValuePath\",\"@optionLabelPath\",\"@disabled\",\"item\"],false,[\"plutof-select/object\",\"content\",\"default-to\",\"selectedValue\",\"prompt\",\"selectClass\",\"common/record-list\",\"get\"]]",
    "moduleName": "plutof/templates/components/multiple-selection.hbs",
    "isStrictMode": false
  });
});