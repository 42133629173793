define("plutof/templates/components/plutof-select/direct", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "br+15dlu",
    "block": "[[[6,[39,0],null,[[\"value\",\"action\",\"class\"],[[33,1],[28,[37,2],[[30,0],[28,[37,3],[[33,1]],null]],null],\"form-control\"]],[[\"default\"],[[[[41,[33,5],[[[6,[30,1,[\"option\"]],null,[[\"value\"],[null]],[[\"default\"],[[[[1,\"            \"],[1,[34,5]],[1,\"\\n\"]],[]]]]]],[]],null],[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[33,8]],null]],null],null,[[[6,[30,1,[\"option\"]],null,[[\"value\"],[[30,2]]],[[\"default\"],[[[[1,\"            \"],[1,[30,2]],[1,\"\\n\"]],[]]]]]],[2]],null]],[1]]]]]],[\"xs\",\"option\"],false,[\"x-select\",\"value\",\"action\",\"mut\",\"if\",\"prompt\",\"each\",\"-track-array\",\"content\"]]",
    "moduleName": "plutof/templates/components/plutof-select/direct.hbs",
    "isStrictMode": false
  });
});