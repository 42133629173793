define("plutof/templates/components/materialsample/sub-repository", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "+OmvoVF+",
    "block": "[[[8,[30,1],[[24,0,\"organization-cell\"]],[[\"@label\"],[[50,\"plutof-labelc\",0,null,[[\"path\"],[\"materialsample.deposited_in\"]]]]],[[\"default\"],[[[[1,\"\\n\"],[6,[39,1],[[30,2,[\"deposited_in\"]]],null,[[\"default\"],[[[[1,\"        \"],[8,[39,2],[[24,\"data-test\",\"deposited_in\"]],[[\"@value\",\"@update\",\"@type\",\"@filters\",\"@params\"],[[30,3],[30,4],\"materialsample/materialsample\",[28,[37,3],null,[[\"access\"],[\"edit\"]]],[28,[37,3],null,[[\"mini\"],[true]]]]],null],[1,\"\\n\"]],[3,4]]]]]],[]]]]],[1,\"\\n\\n\"],[8,[30,1],null,[[\"@label\"],[[50,\"common/local-label\",0,[\"MaterialSamples.subRepository\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,4],[[24,0,\"mini-input__select\"],[24,\"data-test\",\"sub_repository\"]],[[\"@content\",\"@optionLabelPath\",\"@selection\",\"@selectClass\"],[[30,0,[\"subrepositories\"]],\"name\",[30,2,[\"sub_repository\"]],\"mini-input\"]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@cell\",\"@materialsample\",\"value\",\"update\"],false,[\"component\",\"async/bind-relation\",\"collection/auto-complete\",\"hash\",\"plutof-select/object\"]]",
    "moduleName": "plutof/templates/components/materialsample/sub-repository.hbs",
    "isStrictMode": false
  });
});