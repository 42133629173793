define("plutof/templates/components/clipboard/bulk-update-livingspecimen-general", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "kapaoGch",
    "block": "[[[41,[30,0,[\"inputs\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@columns\"],[4]],[[\"default\"],[[[[1,\"\\n\"],[6,[39,2],null,[[\"config\"],[[33,3,[\"project\"]]]],[[\"default\"],[[[[6,[39,4],[[30,1,[\"value\"]]],null,[[\"default\"],[[[[1,\"                \"],[8,[39,5],null,[[\"@value\",\"@update\",\"@disabled\"],[[30,2],[30,3],[30,1,[\"disabled\"]]]],null],[1,\"\\n\"]],[2,3]]]]]],[1]]]]],[1,\"\\n\"],[6,[39,2],null,[[\"config\"],[[33,3,[\"deposited_in\"]]]],[[\"default\"],[[[[1,\"            \"],[8,[39,6],null,[[\"@value\",\"@update\",\"@type\",\"@filters\",\"@disabled\"],[[30,4,[\"value\"]],[28,[37,7],[[30,0],[28,[37,8],[[30,4,[\"value\"]]],null]],null],\"livingculture/strain\",[28,[37,9],null,[[\"access\"],[\"edit\"]]],[30,4,[\"disabled\"]]]],null],[1,\"\\n\"]],[4]]]]],[1,\"\\n\"],[6,[39,2],null,[[\"config\",\"isLoading\"],[[33,3,[\"sub_repository\"]],[33,10,[\"isPending\"]]]],[[\"default\"],[[[[1,\"            \"],[1,[28,[35,11],null,[[\"content\",\"selection\",\"optionLabelPath\",\"disabled\",\"selectClass\"],[[33,10],[30,5,[\"value\"]],\"name\",[30,5,[\"disabled\"]],\"form-control\"]]]],[1,\"\\n\"]],[5]]]]],[1,\"    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[39,1],null,[[\"@columns\"],[4]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,12],null,[[\"@config\"],[[30,0,[\"inputs\",\"permits\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n\"],[41,[30,6,[\"mainformType\"]],[[[1,\"        \"],[8,[39,13],null,[[\"@data\",\"@mainformConfig\",\"@formPath\"],[[30,6],[30,0,[\"inputs\",\"mainform\"]],\"strain_form\"]],null],[1,\"\\n\"]],[]],null]],[]],null]],[\"iw\",\"value\",\"update\",\"iw\",\"iw\",\"@data\"],false,[\"if\",\"layout/regular-grid\",\"clipboard/input-wrapper\",\"inputs\",\"async/bind-relation\",\"project/auto-complete\",\"collection/auto-complete\",\"action\",\"mut\",\"hash\",\"subrepositories\",\"plutof-select/object\",\"clipboard/bulk-update/permit\",\"clipboard/bulk-edit-measurement-form\"]]",
    "moduleName": "plutof/templates/components/clipboard/bulk-update-livingspecimen-general.hbs",
    "isStrictMode": false
  });
});