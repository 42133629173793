define("plutof/components/clipboard/bulk-update-specimen-general", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/runloop", "@ember/utils", "ember-data", "@ember-decorators/component", "plutof/components/clipboard/bulk-update", "plutof/misc/abstract", "plutof/utils/model"], function (_exports, _component, _object, _computed, _runloop, _utils, _emberData, _component2, _bulkUpdate, _abstract, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let BulkUpdateSpecimenGeneral = (_dec = (0, _component2.classNames)('bulk-update-specimen-general'), _dec2 = (0, _computed.alias)('data.inputs'), _dec3 = (0, _model.choices)('determination.determination.assessment'), _dec4 = (0, _object.computed)('data.data.deposited_in.id'), _dec(_class = (_class2 = class BulkUpdateSpecimenGeneral extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "inputs", _descriptor, this);
      _initializerDefineProperty(this, "assessments", _descriptor2, this);
    }
    get subrepositories() {
      const depositedInId = this.get('data.data.deposited_in.id');
      return _emberData.default.PromiseArray.create({
        promise: (0, _utils.isNone)(depositedInId) ? _abstract.EMPTY_PROMISE : this.store.query('agent/repository', {
          parent_organization: depositedInId
        })
      });
    }
    init() {
      super.init(...arguments);
      const inputs = {
        project: (0, _bulkUpdate.createInput)('belongsTo', {
          labelPath: 'General.Project',
          labelSource: 'local'
        }),
        deposited_in: (0, _bulkUpdate.createInput)('belongsTo', {
          labelPath: 'specimen.deposited_in'
        }),
        sub_repository: (0, _bulkUpdate.createInput)('belongsTo', {
          labelPath: 'specimen.sub_repository'
        }),
        location_in_collection: (0, _bulkUpdate.createInput)('belongsTo', {
          labelPath: 'specimen.location_in_collection'
        }),
        remarks: (0, _bulkUpdate.createInput)('string', {
          labelPath: 'specimen.remarks'
        }),
        change_seq_project: (0, _bulkUpdate.createInput)('boolean', {
          labelPath: 'bulk-update.specimens.change_seq_project',
          labelSource: 'endpoint'
        }),
        mainform: (0, _bulkUpdate.createInput)('belongsTo', {
          labelPath: 'specimen.mainform',
          canDelete: false
        }),
        identification_assessment: (0, _bulkUpdate.createInput)('number', {
          labelPath: 'bulk-update.specimens.identification_assessment',
          labelSource: 'endpoint'
        }),
        permits: (0, _bulkUpdate.createInput)('hasMany', {
          labelPath: 'permit.permit',
          labelSource: 'local'
        })
      };

      // XXX TODO: This must be done whereever data comes from (and holding it as a dumb object
      // with unknown structure is also a no-no). The component must just render the
      // form
      //
      // XXX: This is just a stopgap measure so that I don't have to dig this whole yard up
      // (even though I should)
      (0, _runloop.next)(() => {
        this.set('data.inputs', (0, _bulkUpdate.processInputs)(inputs, this.data));
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "inputs", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "assessments", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "subrepositories", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "subrepositories"), _class2.prototype), _class2)) || _class);
  var _default = _exports.default = BulkUpdateSpecimenGeneral;
});