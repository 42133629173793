define("plutof/components/sequence/accession-numbers", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/utils", "plutof/misc/config", "plutof/utils/notifications"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _computed, _service, _utils, _config, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div data-test="Sequence::AccessionNumbers">
      <div class="add-component no-padding">
          <div class="component-part {{if this.codeIsIncorrect 'has-error'}}">
              {{common/local-label "Sequences.id"}}
  
              <Input
                  @type="text"
                  @value={{this.id_in_database}}
                  @disabled={{@disabled}}
                  class="form-control control default-focus-control"
                  data-test="id_in_database" />
          </div>
  
          <div class="component-part {{if this.databaseIsIncorrect 'has-error'}}">
              {{plutof-labelc "sequencedatabase.database"}}
  
              <PlutofSelect::Object
                  @content={{@databases}}
                  @selection={{this.database}}
                  @disabled={{@disabled}}
                  @selectClass="form-control control"
                  data-test="database" />
          </div>
  
          <div class="component-part component-part--button">
              <button
                  {{on "click" this.add}}
                  disabled={{or @disabled (not this.fieldsAreValid)}}
                  class="btn btn-default control"
                  data-test="add"
              >
                  <span class={{icon "ok"}}></span>
                  {{i18n-t "General.Add"}}
              </button>
          </div>
      </div>
  
      <Common::RecordList
          @records={{@identifiers}}
          @remove={{unless @disabled this.remove}} />
  </div>
  
  */
  {
    "id": "yRG1Ji77",
    "block": "[[[10,0],[14,\"data-test\",\"Sequence::AccessionNumbers\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"add-component no-padding\"],[12],[1,\"\\n        \"],[10,0],[15,0,[29,[\"component-part \",[52,[30,0,[\"codeIsIncorrect\"]],\"has-error\"]]]],[12],[1,\"\\n            \"],[1,[28,[35,1],[\"Sequences.id\"],null]],[1,\"\\n\\n            \"],[8,[39,2],[[24,0,\"form-control control default-focus-control\"],[24,\"data-test\",\"id_in_database\"]],[[\"@type\",\"@value\",\"@disabled\"],[\"text\",[30,0,[\"id_in_database\"]],[30,1]]],null],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[15,0,[29,[\"component-part \",[52,[30,0,[\"databaseIsIncorrect\"]],\"has-error\"]]]],[12],[1,\"\\n            \"],[1,[28,[35,3],[\"sequencedatabase.database\"],null]],[1,\"\\n\\n            \"],[8,[39,4],[[24,\"data-test\",\"database\"]],[[\"@content\",\"@selection\",\"@disabled\",\"@selectClass\"],[[30,2],[30,0,[\"database\"]],[30,1],\"form-control control\"]],null],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[14,0,\"component-part component-part--button\"],[12],[1,\"\\n            \"],[11,\"button\"],[16,\"disabled\",[28,[37,5],[[30,1],[28,[37,6],[[30,0,[\"fieldsAreValid\"]]],null]],null]],[24,0,\"btn btn-default control\"],[24,\"data-test\",\"add\"],[4,[38,7],[\"click\",[30,0,[\"add\"]]],null],[12],[1,\"\\n                \"],[10,1],[15,0,[28,[37,8],[\"ok\"],null]],[12],[13],[1,\"\\n                \"],[1,[28,[35,9],[\"General.Add\"],null]],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[39,10],null,[[\"@records\",\"@remove\"],[[30,3],[52,[51,[30,1]],[30,0,[\"remove\"]]]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@disabled\",\"@databases\",\"@identifiers\"],false,[\"if\",\"common/local-label\",\"input\",\"plutof-labelc\",\"plutof-select/object\",\"or\",\"not\",\"on\",\"icon\",\"i18n-t\",\"common/record-list\",\"unless\"]]",
    "moduleName": "plutof/components/sequence/accession-numbers.hbs",
    "isStrictMode": false
  });
  let AccessionNumbers = (_dec = (0, _object.computed)('id_in_database', 'identifiersAreEmpty'), _dec2 = (0, _object.computed)('database', 'identifiersAreEmpty'), _dec3 = (0, _computed.empty)('args.identifiers'), _dec4 = (0, _computed.and)('id_in_database', 'database'), _class = class AccessionNumbers extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "i18n", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "database", _descriptor3, this);
      _initializerDefineProperty(this, "id_in_database", _descriptor4, this);
      _initializerDefineProperty(this, "identifiersAreEmpty", _descriptor5, this);
      _initializerDefineProperty(this, "fieldsAreValid", _descriptor6, this);
    }
    async isRemovableNumber(accno) {
      if (this.args.sequence.isUnite) {
        return true;
      }
      const db = await accno.database;
      return db.id !== _config.default.Sequence.UNITE_DATABASE_ID;
    }
    add() {
      const accno = this.store.createRecord('taxonoccurrence/sequence/sequencedatabase', {
        sequence: this.args.sequence,
        database: this.database,
        id_in_database: this.id_in_database
      });
      this.args.identifiers.pushObject(accno);
      this.database = null;
      this.id_in_database = null;
    }
    async remove(accno) {
      const canRemove = accno.isNew || (await this.isRemovableNumber(accno));
      if (canRemove) {
        this.args.identifiers.removeObject(accno);
        this.args.objectsToDelete.pushObject(accno);
      } else {
        (0, _notifications.displayNotification)('error', this.i18n.translate('Sequences.cannotRemoveUniteID'));
      }
    }
    get codeIsIncorrect() {
      return (0, _utils.isBlank)(this.id_in_database) && this.identifiersAreEmpty;
    }
    get databaseIsIncorrect() {
      return (0, _utils.isNone)(this.database) && this.identifiersAreEmpty;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "i18n", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "database", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "id_in_database", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "add", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "remove", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "codeIsIncorrect", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "codeIsIncorrect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "databaseIsIncorrect", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "databaseIsIncorrect"), _class.prototype), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "identifiersAreEmpty", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "fieldsAreValid", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AccessionNumbers);
});