define("plutof/templates/components/plutof-select/value", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "sdjsR368",
    "block": "[[[6,[39,0],null,[[\"value\",\"action\",\"class\",\"disabled\",\"skipDefault\"],[[30,0,[\"value\"]],[28,[37,1],[[30,0],\"valueChanged\"],null],[30,0,[\"selectClass\"]],[30,0,[\"disabled\"]],[30,1]]],[[\"default\"],[[[[41,[30,0,[\"prompt\"]],[[[6,[30,2,[\"option\"]],null,[[\"value\"],[null]],[[\"default\"],[[[[1,\"            \"],[1,[30,0,[\"prompt\"]]],[1,\"\\n\"]],[]]]]]],[]],null],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[33,5]],null]],null],null,[[[6,[30,2,[\"option\"]],null,[[\"value\"],[[28,[37,6],[[30,3],[33,7]],null]]],[[\"default\"],[[[[1,\"            \"],[1,[28,[35,6],[[30,3],[30,0,[\"optionLabelPath\"]]],null]],[1,\"\\n\"]],[]]]]]],[3]],null]],[2]]]]]],[\"@skipDefault\",\"xs\",\"option\"],false,[\"x-select\",\"action\",\"if\",\"each\",\"-track-array\",\"content\",\"get\",\"optionValuePath\"]]",
    "moduleName": "plutof/templates/components/plutof-select/value.hbs",
    "isStrictMode": false
  });
});